//
// reboot.scss
//

// Link

a {
  @if $enable-transitions {
    transition: $transition-base;
    transition-property: color;
  }
}
