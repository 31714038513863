
.facetwp-selection-label {
  // display:none;
}

.facetwp-selections ul {
  @extend .ml-0 ;
  @extend .pl-0 ;
}

.facetwp-selection-value {
  @extend .badge ;
  @extend .badge-primary ;
  @extend .mb-1 ;
  @extend .pr-4 ;
  @extend .pl-2 ;
  @extend .text-dark ;
}



.facet-label {

  display:none !important;
}


.facetwp-facet {
    margin-bottom: 0px !important;
}
