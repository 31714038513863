//
// flickity.scss
//

.flickity-viewport {
  cursor: auto !important;
}


// Responsive initialization
//
// Requires "watchCSS" to be "true"

.flickity::after {
  display: none;
  content: 'flickity';
}

.flickity-none::after {
  display: none;
  content: '';
}

@each $breakpoint, $value in $grid-breakpoints {
  @if $breakpoint !='xs' {
    @include media-breakpoint-up(#{$breakpoint}) {
      .flickity-#{$breakpoint}::after {
        content: 'flickity';
      }

      .flickity-#{$breakpoint}-none::after {
        content: '';
      }
    }
  }
}


// Page dots

.flickity-page-dots {
  position: relative;
  bottom: 0;
  margin-top: 3rem;
}

.flickity-page-dots .dot {
  width: .5rem;
  height: .5rem;
  margin-left: 3px;
  margin-right: 3px;
  background-color: $gray-200;
  opacity: 1;

  @if $enable-transitions {
    transition: $transition-base;
  }

  @include hover {
    background-color: $gray-250;
  }

  &.is-selected {
    background-color: $primary;
  }
}


// Progress

.flickity-page-dots-progress .flickity-page-dots {
  display: flex;
  width: 100%;
  max-width: 570px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.flickity-page-dots-progress .flickity-page-dots .dot {
  flex: 1;
  height: .25rem;
  margin: 0;
  border-radius: 0;
  background-color: $primary;

  &.is-selected~.dot {
    background-color: $gray-100;
  }
}


// Inner

.flickity-page-dots-inner .flickity-page-dots {
  position: absolute;
  bottom: 1.5rem;
}


// Buttons

.flickity-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 0;
  background-color: $white;
  z-index: 1;

  @if $enable-transitions {
    transition: $transition-base;
  }

  @include hover-focus-active {
    color: $gray-350;
    box-shadow: none;
    opacity: 1;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.flickity-button::before {
  font-family: "Feather";
  font-size: $font-size-lg;
  line-height: 1;
}

.flickity-button>svg {
  display: none;
}

.flickity-button.previous {
  left: 0;
}

.flickity-button.previous::before {
  content: "\e92f";
}

.flickity-button.next {
  right: 0;
}

.flickity-button.next::before {
  content: "\e930";
}


// Large

.flickity-buttons-lg .flickity-button {
  background-color: transparent;
  color: $gray-300;

  @include hover {
    background-color: transparent;
    color: $body-color;
  }
}

.flickity-buttons-lg .flickity-button::before {
  font-size: $h3-font-size;
}


// Offset

.flickity-buttons-offset .flickity-button {
  transform: translateY(-125%);
}


// Left bottom

.flickity-buttons-bottom-left .flickity-button {
  top: auto;
  bottom: 0;
  transform: none;
}

.flickity-buttons-bottom-left .flickity-button.previous {
  left: 0;
}

.flickity-buttons-bottom-left .flickity-button.next {
  left: 42px;
}


// Nav

.flickity-nav .flickity-slider>* {
  opacity: .8;
}

.flickity-nav .is-nav-selected {
  opacity: 1;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $border-width * 2;
    padding: inherit;
    background-color: $body-color;
    background-clip: content-box;
  }
}


// No gutters fix

.row.no-gutters>.flickity-viewport>.flickity-slider>[class*="col"] {
  padding-left: 0;
  padding-right: 0;
}


// Vertical
//
// Stacks items vertically and disabled drag functionality

.flickity-vertical {
  height: 100% !important;
}

.flickity-vertical .flickity-viewport {
  position: relative !important;
  height: 100% !important;
}

.flickity-vertical .flickity-slider {
  position: relative;
  transform: none !important;
}

.flickity-vertical .flickity-slider>* {
  position: relative !important;
  left: 0 !important;
}


// Light

.flickity-light .flickity-button {

  @include hover-focus-active {
    color: $white;
  }
}


/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled{position:relative}.flickity-enabled:focus{outline:0}.flickity-viewport{overflow:hidden;position:relative;height:100%}.flickity-slider{position:absolute;width:100%;height:100%}.flickity-enabled.is-draggable{-webkit-tap-highlight-color:transparent;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.flickity-enabled.is-draggable .flickity-viewport{cursor:move;cursor:-webkit-grab;cursor:grab}.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down{cursor:-webkit-grabbing;cursor:grabbing}.flickity-button{position:absolute;background:hsla(0,0%,100%,.75);border:none;color:#333}.flickity-button:hover{background:#fff;cursor:pointer}.flickity-button:focus{outline:0;box-shadow:0 0 0 5px #19f}.flickity-button:active{opacity:.6}.flickity-button:disabled{opacity:.3;cursor:auto;pointer-events:none}.flickity-button-icon{fill:currentColor}.flickity-prev-next-button{top:50%;width:44px;height:44px;border-radius:50%;transform:translateY(-50%)}.flickity-prev-next-button.previous{left:10px}.flickity-prev-next-button.next{right:10px}.flickity-rtl .flickity-prev-next-button.previous{left:auto;right:10px}.flickity-rtl .flickity-prev-next-button.next{right:auto;left:10px}.flickity-prev-next-button .flickity-button-icon{position:absolute;left:20%;top:20%;width:60%;height:60%}.flickity-page-dots{position:absolute;width:100%;bottom:-25px;padding:0;margin:0;list-style:none;text-align:center;line-height:1}.flickity-rtl .flickity-page-dots{direction:rtl}.flickity-page-dots .dot{display:inline-block;width:10px;height:10px;margin:0 8px;background:#333;border-radius:50%;opacity:.25;cursor:pointer}.flickity-page-dots .dot.is-selected{opacity:1}
