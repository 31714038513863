//
// user.scss
// Use this to write your custom SCSS
//

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('../fonts/open-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v17-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/open-sans-v17-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('../fonts/open-sans-v17-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v17-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v17-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}

$font-family-base: 'Open Sans' !default;

$font-weight-base: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 700 !default;


$primary: #EC008C !default;
$secondary: #a6a6a6 !default;



.woocommerce-message::before {
    content: "\e015";
    color: $primary;
}

.woocommerce-message {
    border-top-color: $primary;
}


.woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button {

  @extend .btn;
  @extend .btn-primary;
  @extend .text-white;

}


#order_review .table-sm td,
#order_review .table-sm th {
    padding: 4px;
}


.woocommerce form .form-row-wide, .woocommerce-page form .form-row-wide {
clear:none !important;
}
